import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./style.css";

const Privacy = () => {

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 12, lg: 15 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Privacy polices
                        </Typography>
                        <hr />

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, fontSize: '12px' }}>
                            However, please note that we are not responsible for any use of your personal information you provide to third-party applications or websites that may be accessed via the Products or Websites. We recommend that you review the privacy policy of any third-party applications or websites that you use.
                            <Typography variant="h6" sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 1, fontSize: '15px' }}>
                                <b>COLLECTION OF PERSONAL INFORMATION</b>
                            </Typography>



                            When you use our Products or Websites, you may be asked for personally identifiable information such as your name, address, date of birth, email address, telephone number.

                            By giving us such information, you will need to consent to our using it in the manner described in this policy.

                            You may withdraw your consent at any time by Contacting us, We will return or destroy your personal information within five days of receipt of your withdrawal of consent.
                            Cookie information, pages you have requested and your IP address may also be recorded by us and/or third parties from your browser as set out in this policy.
                        </Typography>




                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Privacy;